import { render, staticRenderFns } from "./app-manage-log.vue?vue&type=template&id=f920f764&scoped=true&"
import script from "./app-manage-log.vue?vue&type=script&lang=ts&"
export * from "./app-manage-log.vue?vue&type=script&lang=ts&"
import style0 from "./app-manage-log.vue?vue&type=style&index=0&id=f920f764&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f920f764",
  null
  
)

export default component.exports