import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { LogService } from '@/sevices';
import * as Log from '@/typings/log';
import { CTable } from '@cloudpivot-hermes/common-components';
let AppManageLog = class AppManageLog extends Vue {
    constructor() {
        super(...arguments);
        this.isShowFilter = false;
        this.resultType = {
            0: '成功',
            1: '失败',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'userName',
                key: 'userName',
                ellipsis: true,
                slots: { title: 'userNameTitle', name: '用户' },
            },
            {
                dataIndex: 'appName',
                key: 'appName',
                ellipsis: true,
                slots: { title: 'appNameTitle', name: '应用' },
            },
            {
                dataIndex: 'urlDesc',
                key: 'urlDesc',
                ellipsis: true,
                slots: { title: 'urlDescTitle', name: '操作描述' },
            },
            {
                dataIndex: 'operationTime',
                key: 'operationTime',
                ellipsis: true,
                slots: { title: 'operationTimeTitle', name: '请求时间' },
            },
            {
                dataIndex: 'ip',
                key: 'ip',
                ellipsis: true,
                slots: { title: 'ipTitle', name: '请求IP' },
            },
            {
                width: 100,
                dataIndex: 'result',
                key: 'result',
                ellipsis: true,
                scopedSlots: { customRender: 'result' },
                slots: { title: 'resultTitle', name: '请求结果' },
            },
        ];
        this.searchParams = {};
    }
    showFilter() {
        this.isShowFilter = !this.isShowFilter;
    }
    closeFilter() {
        this.isShowFilter = false;
    }
    /**
     * 搜索
     */
    search(searchParams) {
        this.searchParams = searchParams;
        this.cTable.pagination.current = 1;
        this.showFilter();
        this.cTable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            ...this.searchParams,
            logType: Log.APP_MANAGE_LOG,
            page: params.page - 1,
        };
        const res = await LogService.getAppManageLogList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.resultType = this.resultType[item.result];
            });
        }
        return res;
    }
};
__decorate([
    Ref()
], AppManageLog.prototype, "cTable", void 0);
AppManageLog = __decorate([
    Component({
        name: 'AppManageLog',
        components: {
            CTable,
            FilterDrawer: () => import('@/components/sys-manage/log-info/filter-drawer'),
            FilterAppManageLog: () => import('@/components/sys-manage/log-info/filter-drawer/filter-app-manage-log.vue'),
        },
    })
], AppManageLog);
export default AppManageLog;
